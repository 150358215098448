<div *ngIf="footer.sponsors && footer?.sponsors_title"
     class="sponsors__wrapper">
  <div class="sponsors__content">
    <h2 class="font-h2">{{footer?.sponsors_title}}</h2>
    <div *ngFor="let sponsor of footer.sponsors"
         class="sponsors__items">
      <div *ngFor="let item of sponsor.item"
           class="sponsors__item">
        <amg-link [path]="item.link">
          <amg-image [alt]="item.icon"
                     [src]="item.icon"></amg-image>
        </amg-link>
      </div>
    </div>
  </div>
</div>

<footer class="footer">
  <div class="footer__content--top">
    <div class="footer__content">
      <div class="footer__side">
        <div class="footer__logo-wrapper">
          <amg-link class="footer__logo"
                    path="/#"></amg-link>

          <p class="font-meta font-small-text">{{footer?.copyright}}</p>
        </div>

        <div class="footer__social">
          <p class="footer__social-header text-uppercase font-meta font-small-text">{{footer?.social}}</p>

          <div class="footer__social-items">
            <amg-link *ngFor="let site of socialMenu"
                      [cssClass]="site.css_class"
                      [path]="site.url"
                      class="footer__social-item">
              <span class="social-icon"></span>
            </amg-link>
          </div>
        </div>
      </div>

      <div class="footer__nav">
        <div *ngFor="let item of footerNavigation; let i = index"
             class="footer__nav-item font-navigation">
          <amg-link [cssClass]="item.css_class"
                    [path]="item.url"
                    [text]="item.title">
          </amg-link>

          <div *ngIf="item?.child_items"
               class="footer__nav-item footer__nav-item--child font-sub-items-navigation">
            <amg-link *ngFor="let child of item?.child_items"
                      [cssClass]="item.css_class"
                      [path]="child.url"
                      [text]="child.title">
            </amg-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer__content--bottom font-meta-information">
    <div class="footer__content">
      <amg-link *ngFor="let item of footerBottomLinks"
                [fullPathSelectedItem]="true"
                [path]="item.url"
                [text]="item.title">
      </amg-link>
    </div>
  </div>
</footer>
