import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BaseComponentDirective, BooleanObservableService } from '@streamamg/amg-common';
import { CLOUD_PAY_MODAL_KEY, CloudPayService } from '@streamamg/cloud-pay-lib';
import { WordpressService } from '@streamamg/wordpress-lib';
import { WpFixedPages } from '../../types/wordpress/fixed-pages.type';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

@Component({
  selector: 'app-authentication-button',
  templateUrl: './authentication-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
  ],
})
export class AuthenticationButtonComponent extends BaseComponentDirective implements OnInit {

  @Input() typeCta: 'login' | 'logout' | 'login_to_your_account' | 'my_account' | 'register' | 'subscribe' = 'login';
  @Input() iconOnly: boolean;
  @Input() showIcon: boolean;
  @Input() buttonClasses = '';
  public ctaUrl: string;

  constructor(
    protected cd: ChangeDetectorRef,
    private booleanObservableService: BooleanObservableService,
    private cloudPayService: CloudPayService,
    private wordpressService: WordpressService,
  ) {
    super();
  }

  ngOnInit(): void {

    this.buttonClasses += ` btn--${this.typeCta}`;
    this.cd.detectChanges();

    if (!['login', 'logout'].includes(this.typeCta)) {

      const pagesPaths = this.wordpressService.getPagesPaths<WpFixedPages>();

      switch (this.typeCta) {
        case 'my_account': {
          this.ctaUrl = `${pagesPaths?.my_account?.confs?.post_permalink}`;
          break;
        }
        case 'register': {
          this.ctaUrl = `${pagesPaths.register.confs.post_permalink}`;
          break;
        }
        case 'subscribe': {
          this.ctaUrl = `${pagesPaths.packages.confs.post_permalink}`;
          break;
        }
      }

      this.cd.detectChanges();

    }
  }

  public onBtnClick($event: Event) {
    $event.preventDefault();
    switch (this.typeCta) {
      case 'login': {
        this.onLogin();
        break;
      }
      case 'login_to_your_account': {
        this.onLogin();
        break;
      }
      case 'logout': {
        this.onLogOut();
        break;
      }
    }
  }

  public onLogin(): void {
    this.booleanObservableService.trigger(CLOUD_PAY_MODAL_KEY, true);
  }

  public onLogOut(): void {

    this.cloudPayService.logOut();
  }
}
