import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CloudMatrixModule } from '@streamamg/cloud-matrix-lib';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CloudPayModule } from '@streamamg/cloud-pay-lib';
import { MenuModule } from '@streamamg/amg-common';
import {
  BackToTopModule,
  DynamicComponentLoaderModule,
  LoaderInterceptor,
  LoaderModule,
} from '@streamamg/components-lib';
import { StreamPlayLiveModule, StreamPlayModule } from '@streamamg/stream-play-lib';
import { EffectsModule } from '@ngrx/effects';
import { WordpressModule } from '@streamamg/wordpress-lib';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { dynamicComponentManifests } from './dynamic-component-manifests';
import { effects, metaReducers, reducers } from './store';
import { configs } from './configs';
import { LoginModalModule } from './modules/login-modal/login-modal.module';
import { HeaderComponent } from './modules/header/header.component';
import { FooterComponent } from './modules/footer/footer.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot(effects),
    environment.production ? []: StoreDevtoolsModule.instrument(),
    LoaderModule,
    BackToTopModule,
    StreamPlayModule,
    StreamPlayLiveModule,
    CloudMatrixModule,
    DynamicComponentLoaderModule.forRoot(dynamicComponentManifests),
    WordpressModule.forRoot(
      {
        wordpressPath: configs.wordpress.paths.api,
        globalSettingsUrl: `${configs.wordpress.paths.api}${configs.wordpress.subPaths.globalSettings}`,
        pagesUrl: [
          `${configs.wordpress.paths.api}${configs.wordpress.subPaths.pages}`,
          `${configs.wordpress.paths.api}${configs.wordpress.subPaths.pagesExtra}`,
          `${configs.wordpress.paths.api}${configs.wordpress.subPaths.teams}`,
        ],
      },
    ),
    CloudPayModule.forRoot(
      {
        url: environment.cloudPay,
        language: 'en',
        isSso: false,
      },
    ),
    LoaderModule.forRoot(
      {
        whitelistStrings: [
          'assets.streamplay.streamamg.com',
        ],
      },
    ),
    LoginModalModule,
    MenuModule.forRoot({
      baseUrl: environment.wordpress.baseUrl,
      endpoint: configs.wordpress.paths.menusApi,
    }),
    StreamPlayLiveModule.forRoot({}),
    HeaderComponent,
    FooterComponent,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
