import { createReducer, on } from '@ngrx/store';
import { loadTeamsFailure, loadTeamsSuccess, selectTeamById, selectWpTeamBySlug } from './teams.actions';
import { StoreState } from '../index';
import { TeamStore } from '../../types/store.type';
import { WpTeam } from '../../templates/team-template/team.types';

export const featureKey = 'teams';

export interface State extends StoreState {
  teamId?: number;
  slug?: string;
  teams?: TeamStore[];
  wpTeams?: WpTeam[];
}

export const initialState: State = {};

export const reducer = createReducer(
  initialState,
  on(loadTeamsSuccess, (state, {data}) => ({
    ...state,
    teams: data.teams,
    wpTeams: data.wpTeams,
    status: {...initialState.status, ready: true},
  })),
  on(loadTeamsFailure, (state, {error}) => ({...state, status: {loading: false, error, ready: true}})),
  on(selectTeamById, (state, {teamId}) => ({...state, teamId})),
  on(selectWpTeamBySlug, (state, {slug}) => ({...state, slug})),
);

