import { createAction, props } from '@ngrx/store';
import { State } from './teams.reducer';
import { CompetitionStore } from '../../types/store.type';

export const loadTeams = createAction(
  '[Teams] Load Teams',
  props<{ competitionsList: CompetitionStore[] }>(),
);

export const loadTeamsSuccess = createAction(
  '[Teams] Load Teams Success',
  props<{ data: State }>(),
);

export const loadTeamsFailure = createAction(
  '[Teams] Load Teams Failure',
  props<any>(),
);

export const selectTeamById = createAction(
  '[Teams] Select Team by ID',
  props<{ teamId: number }>(),
);

export const selectWpTeamBySlug = createAction(
  '[Teams] Select WP Team by Slug',
  props<{ slug: string }>(),
);

