import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkModule } from '@streamamg/components-lib';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { BaseComponentDirective, BooleanObservableService } from '@streamamg/amg-common';
import { CLOUD_PAY_MODAL_KEY } from '@streamamg/cloud-pay-lib';
import { AuthenticationButtonComponent } from '../authentication-button/authentication-button.component';

@Component({
  selector: 'app-login-header',
  standalone: true,
  imports: [CommonModule, RouterModule, SharedModule, LinkModule, AuthenticationButtonComponent],
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginHeaderComponent extends BaseComponentDirective implements AfterViewInit, OnDestroy {
  @ViewChild('menuContainer') $menuContainer: ElementRef;
  @Input() isLoggedIn: boolean;
  public menuOpened: boolean;

  constructor(
    private booleanObservableService: BooleanObservableService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.$menuContainer?.nativeElement) {
      window.addEventListener('click', this.onClickMenu);
    }
  }

  onClickMenu = (e) => {
    if (this.$menuContainer?.nativeElement?.contains(e.target)) {
      if (this.isLoggedIn) {
        this.menuOpened = !this.menuOpened;
        this.cdr.detectChanges();
        return;
      }
      this.booleanObservableService.trigger(CLOUD_PAY_MODAL_KEY, true);
    } else {
      if (this.isLoggedIn) {
        this.menuOpened = false;
        this.cdr.detectChanges();
      }
    }
  };

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    window.removeEventListener('click', this.onClickMenu, false);
  }
}
