<amg-loader *ngIf="loading || !showContent"></amg-loader>

<ng-container *ngIf="appReady">
  <app-header></app-header>

  <main [class.show]="showContent"
        class="show-smoothly"
        id="page-container">
    <router-outlet></router-outlet>
  </main>

  <app-footer *ngIf="showContent"></app-footer>
  <amg-back-to-top></amg-back-to-top>
  <app-login-modal></app-login-modal>
</ng-container>

<ng-container #errorPageContainer></ng-container>
