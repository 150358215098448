<button (click)="onBtnClick($event)"
        *ngIf="['login', 'logout', 'login_to_your_account'].includes(typeCta);else cta"
        [attr.aria-label]="typeCta"
        [ngClass]="buttonClasses"
        class="btn"
        data-testid="login-status-button"
        role="button">
  <span *ngIf="showIcon"
        class="btn__icon"></span>
  <span *ngIf="!iconOnly">{{typeCta | label}}</span>
</button>

<ng-template #cta>
  <a [ngClass]="buttonClasses"
     [routerLink]="ctaUrl"
     class="btn">
    <span *ngIf="showIcon"
          class="btn__icon"></span>
    <span *ngIf="!iconOnly">{{typeCta | label}}</span>
  </a>
</ng-template>

