export const environment = {
  production: false,
  streamPlay: {
    production: false,
  },
  wordpress: {
    baseUrl: 'https://wp-staging-curling-canada.streamamg.com',
  },
  cloudPay: 'https://stagingcurlingcanadapayments.streamamg.com',
  curling: {
    endpoint: 'https://curling-canada-api.staging.streamamg.com/competitions',
    // endpoint: 'https://legacy-curlingio.global.ssl.fastly.net/api/organizations/qw4LUsJ1_aQ/competitions',
  },
};
