import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BaseComponentDirective, DomUtils, MenuService, WpMenuDataItem } from '@streamamg/amg-common';
import { CloudPayService, CloudPayState } from '@streamamg/cloud-pay-lib';
import { CommonModule } from '@angular/common';
import { LinkModule } from '@streamamg/components-lib';
import { SharedModule } from '../../shared/shared.module';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { WordpressService } from '@streamamg/wordpress-lib';
import { WpFixedPages } from '../../types/wordpress/fixed-pages.type';
import { LoginHeaderComponent } from '../login-header/login-header.component';
import { AuthenticationButtonComponent } from '../authentication-button/authentication-button.component';
import { WpFilters, WpGlobalSettings } from '../../types/wordpress/global-settings.type';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LinkModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    LoginHeaderComponent,
    AuthenticationButtonComponent,
  ],
})
export class HeaderComponent extends BaseComponentDirective implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInputRef;
  public menu: WpMenuDataItem[];
  public menuOpened: boolean;
  public userState: CloudPayState;
  public submenusOpened: { [x: string]: boolean };
  public searchOpened: boolean;
  public subscribeLink = '';
  public formSearch: FormGroup = new FormGroup({
    q: new FormControl(''),
  });
  public wpFilters: WpFilters;

  constructor(
    private cloudPayService: CloudPayService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private menuService: MenuService,
    private wordpressService: WordpressService,
  ) {

    super();
    this.menuOpened = false;
    this.submenusOpened = {};
  }

  ngOnInit(): void {
    this.subscribeLink = this.wordpressService.getPagesPaths<WpFixedPages>().packages?.confs?.post_permalink;
    this.wpFilters = this.wordpressService.getWpGlobalSettings<WpGlobalSettings>().filters;
    super.subSink = this.menuService.getMenuDataItems('header')
      .subscribe((menu) => {
        if (menu) {
          this.menu = menu.items;
          this.cd.markForCheck();
        }
      });

    super.subSink = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {

        this.closeMenu();
      });

    super.subSink = this.cloudPayService.cloudPayState()
      .subscribe((state) => {

        this.userState = state;
        this.cd.markForCheck();
      });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const headerHeight = 84;
    if (window.scrollY > headerHeight) {
      document.querySelector('header')?.classList.add('solid-bg');
    } else {
      document.querySelector('header')?.classList.remove('solid-bg');
    }
  }

  public closeMenu(): void {
    this.submenusOpened = {};
    if (this.menuOpened) {
      this.searchOpened = false;
      this.toggleMenu();
    }
  }

  public searchToggle(): void {
    this.searchOpened = !this.searchOpened;

    if (this.searchOpened) {
      // after css transition is terminated
      setTimeout(() => {
        this.searchInputRef.nativeElement.focus();
      }, 700);
    }

    this.cd.detectChanges();
  }

  public toggleMenu(): void {
    this.menuOpened = !this.menuOpened;
    DomUtils.toggleOverlayClass(!this.menuOpened);
    this.cd.detectChanges();
  }

  public toggleSubmenu(index: number): void {
    this.submenusOpened[`i-${index}`] = !this.submenusOpened[`i-${index}`];
    this.cd.detectChanges();
  }

  public submitSearch(event: Event): void {
    event.preventDefault();
    const filters = {...this.formSearch.value};
    Object.keys(filters).forEach((key) => (!filters[key] || filters[key] === '') && delete filters[key]);

    const pagesPaths = this.wordpressService.getPagesPaths<WpFixedPages>();
    this.router.navigate([`/${pagesPaths.archive.confs.post_permalink}`], {queryParams: filters});
    this.searchToggle();
    this.formSearch.reset();
    this.searchInputRef.nativeElement.blur();
  }

  public searchClean(): void {
    this.formSearch.reset();
    this.cd.detectChanges();
  }
}
