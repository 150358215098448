import { createAction, props } from '@ngrx/store';
import { State } from './competitions.reducer';

export const loadCompetitions = createAction(
  '[Competitions] Load Competitions',
);

export const loadCompetitionsSuccess = createAction(
  '[Competitions] Load Competitions Success',
  props<{ data: State }>(),
);

export const loadCompetitionsFailure = createAction(
  '[Competitions] Load Competitions Failure',
  props<any>(),
);

export const selectCompetitionById = createAction(
  '[Competitions] Select Competition by ID',
  props<{ competitionId: string }>(),
);

export const selectCompetitionBySlug = createAction(
  '[Competitions] Select Competition by Slug',
  props<{ slug: string }>(),
);

