import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { FixtureApi, TeamApi } from '../types/curling-canada-api.type';
import moment from 'moment/moment';
import { CompetitionStore, FixtureStore, TeamStore } from '../types/store.type';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CurlingCanadaService {

  private fixturesErrorSubject = new BehaviorSubject<boolean>(false);
  public fixturesError$: Observable<boolean> = this.fixturesErrorSubject;

  private readonly mockupData = !!localStorage.getItem('mockup_cc_api');

  constructor(
    protected httpClient: HttpClient,
  ) {

  }

  private static getURL(path: string): string {
    const url = new URL(`${environment.curling.endpoint}/${path}`);
    return url.href;
  }

  public getFixturesByCompetition(competitionId: string): Observable<FixtureApi[]> {

    const url = CurlingCanadaService.getURL(`${competitionId}/games`);

    return this.httpClient.get<FixtureApi[]>(url).pipe(
      catchError(err => {
        console.error('Fixtures by competition are not loaded', err);
        this.fixturesErrorSubject.next(true);
        return throwError(err);
      }),
      catchError(() => of(null)),
    );

  }

  public getFixtures(competitionList: CompetitionStore[]): Observable<FixtureStore[]> {

    if (!competitionList || competitionList?.length === 0) {

      return of(null);
    }

    const subject = new ReplaySubject<FixtureStore[]>(1);

    const objectList: { [id: string]: Observable<FixtureApi[]> } = {};

    competitionList.forEach((item) => {
      objectList[item.id] = this.getFixturesByCompetition(item.id);
    });

    forkJoin(objectList).subscribe((fixturesList: { [id: string]: FixtureApi[] }) => {

      const fixtureStoreList: FixtureStore[] = [];

      Object.entries(fixturesList).forEach(([competitionId, items]) => {
        if (items?.length) {

          if (this.mockupData) {
            //preparing mockup data
            items.map((item) => {

              item.state = 'upcoming';

              if (item.draw?.starts_at && item.draw?.starts_at !== 'TBD') {
                const startDate = moment(item.draw.starts_at);
                startDate.set('year', moment().get('year') + 1);
                item.draw.starts_at = startDate.toString();
              }
            });
          } else {

            // remove completed games
            items = items.filter((item) => item.state !== 'final');
          }

          items.forEach((item) => {

            const competition = competitionList.find(item => item.id === competitionId);

            fixtureStoreList.push({
              id: item.id,
              //add competition ID to each fixture
              competition: {
                id: competitionId,
                location: competition.wp?.location,
                name: competition.wp?.confs.post_title,
                isHidden: competition.wp?.hide_competition,
              },
              api: item,
            });
          });

        } else {
          subject.next(null);
        }

      });

      subject.next(fixtureStoreList);
    });

    return subject.asObservable();
  }

  public getTeamsByCompetition(competitionId: string): Observable<TeamApi[]> {

    const url = CurlingCanadaService.getURL(`${competitionId}/teams`);

    return this.httpClient.get<TeamApi[]>(url).pipe(
      catchError(err => {
        console.error('Teams by competitions are not loaded', err);
        this.fixturesErrorSubject.next(true);
        return throwError(err);
      }),
      catchError(() => of(null)),
    );

  }

  public getTeams(competitionList: CompetitionStore[]): Observable<TeamStore[]> {

    if (!competitionList || competitionList?.length === 0) {

      return of(null);
    }

    const subject = new ReplaySubject<TeamStore[]>(1);

    const objectList: { [id: string]: Observable<TeamApi[]> } = {};

    competitionList.forEach((item) => {
      objectList[item.id] = this.getTeamsByCompetition(item.id);
    });

    forkJoin(objectList).subscribe((list: { [id: string]: TeamApi[] }) => {

      const teamStoreList: TeamStore[] = [];

      Object.entries(list).forEach(([competitionId, items]) => {

        items?.forEach((item) => {
          const main_team = item.name.replace(/\(.*\)/, '');
          const transformedName = this.removeSymbolsAfterHash(main_team);
          teamStoreList.push({
            id: item.id,
            competition_id: competitionId,
            main_team: transformedName,
            api: item,
          });
        });
      });

      subject.next(teamStoreList);
    });

    return subject.asObservable();

  }

  public removeSymbolsAfterHash(element: string): string {
    return element.replace(/#[^\s,]+/, '').trim();
  }
}
