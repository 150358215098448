import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromFixtures from './fixtures/fixtures.reducer';
import { FixturesEffects } from './fixtures/fixtures.effects';
import * as fromTeams from './teams/teams.reducer';
import { TeamsEffects } from './teams/teams.effects';
import * as fromWordpress from './wordpress/wordpress.reducer';
import { WordpressEffects } from './wordpress/wordpress.effects';
import * as fromCompetitions from './competitions/competitions.reducer';
import { CompetitionsEffects } from './competitions/competitions.effects';

export interface StoreState {
  status?: {
    ready?: boolean;
    loading?: boolean;
    error?: any
  };
}

export interface AppState {
  [fromTeams.featureKey]: fromTeams.State;
  [fromFixtures.featureKey]: fromFixtures.State;
  [fromWordpress.featureKey]: fromWordpress.State;
  [fromCompetitions.featureKey]: fromCompetitions.State;
}

export const reducers: ActionReducerMap<AppState> = {
  [fromTeams.featureKey]: fromTeams.reducer,
  [fromFixtures.featureKey]: fromFixtures.reducer,
  [fromWordpress.featureKey]: fromWordpress.reducer,
  [fromCompetitions.featureKey]: fromCompetitions.reducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? []: [];

export const effects = [
  TeamsEffects,
  FixturesEffects,
  WordpressEffects,
  CompetitionsEffects,
];
