import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { WpGlobalSettings } from '../../types/wordpress/global-settings.type';
import { WpFixedPages } from '../../types/wordpress/fixed-pages.type';
import { WordpressService } from '@streamamg/wordpress-lib';
import { LoginModalDirective } from '@streamamg/cloud-pay-lib';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent extends LoginModalDirective implements OnInit {
  public wpGlobalSettings: WpGlobalSettings;
  public showPassword = false;
  public pagesPaths: WpFixedPages = this.wordpressService.getPagesPaths();

  constructor(
    private wordpressService: WordpressService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.init();

    this.wpGlobalSettings = this.wordpressService.getWpGlobalSettings<WpGlobalSettings>();
    this.cd.detectChanges();
  }
}
