import { createSelector } from '@ngrx/store';
import { AppState } from './index';
import * as fromFixtures from './fixtures/fixtures.reducer';
import * as fromTeams from './teams/teams.reducer';
import * as fromWordpress from './wordpress/wordpress.reducer';
import * as fromCompetition from './competitions/competitions.reducer';

export const State = (state: AppState) => state;

export const selectApplicationReady = createSelector(
  State,
  (state: AppState) => {

    return (
      state[fromWordpress.featureKey]?.status?.ready &&
      state[fromTeams.featureKey]?.status?.ready &&
      state[fromFixtures.featureKey]?.status?.ready &&
      state[fromCompetition.featureKey]?.status?.ready
    );
  },
);
