import { environment } from '../environments/environment';
import { ViewPermission } from '@streamamg/cloud-matrix-lib/src/lib/models/entitlements.model';

export const configs = {
  google: {
    gtm: 'GTM-NVP26SP',
  },
  carouselItemSize: 25,
  playlistPageSize: 50,
  generalContentPageSize: 24,
  browseContentPageSize: {
    default: 24,
    fhd: 25,
    qhd: 30,
    uhd: 35,
  },

  streamPlayEndPoint: `https://${environment.streamPlay.production ? '': 'staging.'}api.streamplay.streamamg.com/fixtures/ski_snow/p/3001571`,
  // streamPlayEndPoint: `https://${environment.streamPlay.production ? '': 'staging.'}api.streamplay.streamamg.com/fixtures/football/p/3001563`,

  kaltura: {
    kalturaBaseUrl: 'https://open.http.mp.streamamg.com',
    partnerId: '3001571',
    uiConfId: '30031200',
    flashvars: {
      'playerSeekButtonAMG.plugin': true,
      'playerSeekButtonAMG.parent': 'videoHolder',
      'playerSeekButtonAMG.seekValue': 15,
      'currentTimeLabel.order': 10,
      'liveStatus.order': 11,
      'durationLabel.order': 12,
      'playPauseBtn.order': 40,
      'volumeControl.order': 100,
      'chromecast.order': 80,
      'sourceSelector.order': 70,
      'fullScreenBtn.order': 50,
      // 'volumeControl.showSlider': false,
      'volumeControl.layout': 'vertical',
      IframeCustomPluginCss1: '/video-player.css',
      autoPlay: true,
      autoPlayFallbackToMute: false,
      'unMuteOverlayButton.plugin': false,
    },

    multicam: {
      flashvars: {
        'playerSeekButtonAMG.plugin': false,
        IframeCustomPluginCss1: '/multicam-player.css',
        IframeCustomPluginJs1: '/assets/js/kaltura-multicam.js',
      },
    },
  },

  cloudMatrix: {
    defaultEntitlement: ViewPermission.Free,
    feed: 'https://curlingcanada-cm.streamamg.com/api/v1/99318198-d046-4fd1-bee0-edf1c9a64d96/RsjaSwHPcijJHfV6LUmJT4bj5heGcIqSF7N78YyqRgUpRm9BEZ/en/search?sortBy=publicationData.releaseFrom&sortOrder=desc',
  },

  wordpress: {
    paths: {
      api: `${environment.wordpress.baseUrl}/wp-json/wpa/v2/amg`,
      menusApi: `${environment.wordpress.baseUrl}/wp-json/menus/v1/menus`,
    },
    subPaths: {
      globalSettings: '?postType=global_settings',
      pages: '?postType=page',
      pagesExtra: '?postType=page_extra',
      categories: '?postType=category',
      teams: '?postType=team',
      competitions: '?postType=competition',
      tournament_phases: '?postType=tournament_phase',
      genders: '?postType=gender',
      team_categories: '?postType=team_category',
    },
  },
};
