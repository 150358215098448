import { createAction, props } from '@ngrx/store';
import { FixturesFilters } from './fixtures.reducer';
import { CompetitionStore, FixtureStore } from '../../types/store.type';

export const loadFixtures = createAction(
  '[Fixtures] Load',
  props<{ competitionsList: CompetitionStore[] }>(),
);

export const loadFixturesSuccess = createAction(
  '[Fixtures] Load Success',
  props<{ data: FixtureStore[] }>(),
);

export const loadFixturesFailure = createAction(
  '[Fixtures] Load Failure',
  props<any>(),
);

export const filterFixtures = createAction(
  '[Fixtures] Filter',
  props<FixturesFilters>(),
);

// export const filterByCompetition = createAction(
//   '[Fixtures] Filter by Competition',
//   props<{ competitionId: number }>(),
// );

export const resetFilters = createAction(
  '[Fixtures] Reset Filters',
);

export const selectFixture = createAction(
  '[Fixtures] Select single fixture',
  props<{ id?: string }>(),
);
