import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadFixtures, loadFixturesFailure, loadFixturesSuccess } from './fixtures.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { StreamPlayService } from '@streamamg/stream-play-lib';
import { configs } from '../../configs';
import { StreamPlayItem } from '@streamamg/stream-play-lib/src/lib/types/stream-play.type';
import { CurlingCanadaService } from '../../services/curling-canada.service';
import { FixtureStore } from '../../types/store.type';

@Injectable()
export class FixturesEffects {

  loadFixtures$ = createEffect(() => this.actions$.pipe(
    ofType(loadFixtures),
    switchMap((params) => combineLatest([
      this.curlingCanadaService.getFixtures(params.competitionsList),
      this.streamPlayService.triggerApiCall(configs.streamPlayEndPoint, 100, null, null, false, null, true),
    ])),
    map(([fixturesStoreList, streamPlayState]) => {

      const filteredFixtures: FixtureStore[] = [];

      if (fixturesStoreList && streamPlayState) {
        const spFixtures: StreamPlayItem[] = streamPlayState?.data;

        if (spFixtures?.length > 0) {

          spFixtures.forEach((spItem) => {

            const fixtureApi = fixturesStoreList.find((item) => item.id == spItem.externalIds.optaFixtureId);

            if (fixtureApi) {
              fixtureApi.sp = spItem;

              filteredFixtures.push(fixtureApi);
            }
          });
        }

        filteredFixtures.sort((a, b) => {

          if (!a.api?.draw?.starts_at || a.api.draw.starts_at === 'TBD') return 1;
          if (!b.api?.draw?.starts_at || b.api.draw.starts_at === 'TBD') return -1;

          return new Date(a.api.draw?.starts_at).getTime() - new Date(b.api.draw?.starts_at).getTime();
        });

        return loadFixturesSuccess({data: filteredFixtures});
      }

      return loadFixturesFailure({code: 'Not ready'});

    }),
    catchError(error => of(loadFixturesFailure({error}))),
  ), {dispatch: true});

  constructor(
    private actions$: Actions,
    private streamPlayService: StreamPlayService,
    private curlingCanadaService: CurlingCanadaService,
  ) {
  }
}
