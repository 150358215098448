<app-authentication-button
  *ngIf="!isLoggedIn"
  [iconOnly]="true"
  [showIcon]="true"></app-authentication-button>

<div #menuContainer
     *ngIf="isLoggedIn"
     [class.opened]="menuOpened"
     class="login-menu">

  <button aria-label="login"
          class="btn btn--login"
          role="button">
    <span class="btn__icon"></span>
  </button>

  <div
    class="login-menu__arrow"></div>

  <div
    class="login-menu__overlay">

    <div class="login-menu__overlay-cta font-navigation">
      <app-authentication-button
        [showIcon]="false"
        [typeCta]="'my_account'">
      </app-authentication-button>

      <app-authentication-button
        [showIcon]="false"
        [typeCta]="'logout'"></app-authentication-button>
    </div>
  </div>
</div>
