import { createReducer, on } from '@ngrx/store';
import {
  filterFixtures,
  loadFixturesFailure,
  loadFixturesSuccess,
  resetFilters,
  selectFixture,
} from './fixtures.actions';
import { StoreState } from '../index';
import { FixtureStore } from '../../types/store.type';

export const featureKey = 'fixtures';

export interface State extends StoreState {
  selectedId?: string;
  fixtures?: FixtureStore[];
  filters?: FixturesFilters;
}

export interface FixturesFilters {
  competitionId?: string;
  // team?: string;
  // date?: {
  //   from?: Date;
  //   to?: Date;
  // };
}

export const initialState: State = {
  filters: {},
};

export const reducer = createReducer(
  initialState,
  on(loadFixturesSuccess, (state, {data}) => ({
    ...state,
    fixtures: data,
    status: {...initialState.status, ready: true},
  })),
  on(loadFixturesFailure, (state, {payload}) => ({
    ...state,
    status: {
      loading: false,
      error: payload,
      ready: true,
    },
  })),
  on(resetFilters, (state) => ({
    ...state,
    filters: {
      ...initialState.filters,
    },
  })),
  on(filterFixtures, (state, filters) => ({
    ...state,
    filters,
  })),
  on(selectFixture, (state, {id}) => ({
    ...state,
    selectedId: id,
  })),
);
