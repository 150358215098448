import { DynamicComponentManifest } from '@streamamg/components-lib';

export const dynamicComponentManifests: DynamicComponentManifest[] = [

  // Templates
  {
    componentId: 'error_page',
    loadComponent: () => import('./templates/error-template/error-template.component').then((m) => m.ErrorTemplateComponent),
  },
  {
    componentId: 'dynamic',
    loadChildren: () => import('./templates/dynamic-template/dynamic-template.module').then((m) => {
      return {
        moduleRef: m,
        module: m.DynamicTemplateModule,
      };
    }),
  },
  {
    componentId: 'live_player',
    loadComponent: () => import('./templates/video-player-template/live-player-template/live-player-template.component').then((m) => m.LivePlayerTemplateComponent),
  },
  {
    componentId: 'vod_player',
    loadComponent: () => import('./templates/video-player-template/vod-player-template/vod-player-template.component').then((m) => m.VodPlayerTemplateComponent),
  },
  {
    componentId: 'multicam_player',
    loadComponent: () => import('./templates/video-player-template/multicam-template/multicam-template.component').then((m) => m.MulticamTemplateComponent),
  },
  {
    componentId: 'purchase_success',
    loadComponent: () => import('./templates/cloud-pay-template/purchase-success-template/purchase-success-template.component').then((m) => m.PurchaseSuccessTemplateComponent),
  },
  {
    componentId: 'cloud_pay',
    loadComponent: () => import('./templates/cloud-pay-template/cloud-pay-generic-template/cloud-pay-generic-template.component').then((m) => m.CloudPayGenericTemplateComponent),
  },
  {
    componentId: 'register',
    loadComponent: () => import('./templates/cloud-pay-template/register-template/register-template.component').then((m) => m.RegisterTemplateComponent),
  },
  {
    componentId: 'forgot_password',
    loadComponent: () => import('./templates/cloud-pay-template/cloud-pay-generic-template/cloud-pay-generic-template.component').then((m) => m.CloudPayGenericTemplateComponent),
  },
  {
    componentId: 'my_account',
    loadComponent: () => import('./templates/cloud-pay-template/cloud-pay-generic-template/cloud-pay-generic-template.component').then((m) => m.CloudPayGenericTemplateComponent),
  },
  {
    componentId: 'checkout',
    loadComponent: () => import('./templates/cloud-pay-template/checkout-template/checkout-template.component').then((m) => m.CheckoutTemplateComponent),
  },
  {
    componentId: 'archive',
    loadComponent: () => import('./templates/archive-template/archive-template.component').then((m) => m.ArchiveTemplateComponent),
  },
  {
    componentId: 'fixtures',
    loadChildren: () => import('./templates/fixtures-template/fixtures-template.module').then((m) => {
      return {
        moduleRef: m,
        module: m.FixturesTemplateModule,
      };
    }),
  },
  {
    componentId: 'team',
    loadChildren: () => import('./templates/team-template/team-template.module').then((m) => {
      return {
        moduleRef: m,
        module: m.TeamTemplateModule,
      };
    }),
  },
  {
    componentId: 'generic',
    loadComponent: () => import('./templates/generic-template/generic-template.component').then((m) => m.GenericTemplateComponent),
  },
  {
    componentId: 'packages_template',
    loadComponent: () => import('./templates/packages-template/packages-template.component').then((m) => m.PackagesTemplateComponent),
  },
  {
    componentId: 'not_found',
    loadComponent: () => import('./templates/not-found-template/not-found-template.component').then((m) => m.NotFoundTemplateComponent),
  },

  // Modules
  {
    componentId: 'hero',
    loadChildren: () => import('./modules/hero/hero.module').then((m) => {
      return {
        moduleRef: m,
        module: m.HeroModule,
      };
    }),
  },
  {
    componentId: 'editorial',
    loadChildren: () => import('./modules/editorial/editorial.module').then((m) => {
      return {
        moduleRef: m,
        module: m.EditorialModule,
      };
    }),
  },
  {
    componentId: 'accordions',
    loadChildren: () => import('./modules/accordions/accordions.module').then((m) => {
      return {
        moduleRef: m,
        module: m.AccordionsModule,
      };
    }),
  },
  {
    componentId: 'carousel_fixtures',
    loadChildren: () => import('./modules/carousel-fixtures/carousel-fixtures.module').then((m) => {
      return {
        moduleRef: m,
        module: m.CarouselFixturesModule,
      };
    }),
  },
  {
    componentId: 'carousel_teams',
    loadChildren: () => import('./modules/carousel-teams/carousel-teams.module').then((m) => {
      return {
        moduleRef: m,
        module: m.CarouselTeamsModule,
      };
    }),
  },
  {
    componentId: 'carousel_vod',
    loadComponent: () => import('./modules/carousel-vod/carousel-vod.component').then((m) => m.CarouselVodComponent),
  },
  {
    componentId: 'big_list_vod',
    loadComponent: () => import('./modules/big-list-vod/big-list-vod.component').then((m) => m.BigListVodComponent),
  },
  {
    componentId: 'contact_form',
    loadComponent: () => import('./modules/contact-form/contact-form.component').then((m) => m.ContactFormComponent),
  },
];

