import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CloudPayService } from '@streamamg/cloud-pay-lib';
import { AppState } from './store';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { DynamicComponentLoaderService, LoaderService } from '@streamamg/components-lib';
import { AnchorsUtils, CssVarsUtils } from '@streamamg/amg-common';
import { catchError, delay } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { selectApplicationReady } from './store/app.selectors';
import { loadFixtures } from './store/fixtures/fixtures.actions';
import { WordpressService } from '@streamamg/wordpress-lib';
import { DOCUMENT } from '@angular/common';
import packageJson from '../../package.json';
import { loadTeams } from './store/teams/teams.actions';
import { loadWordpress } from './store/wordpress/wordpress.actions';
import { loadCompetitions } from './store/competitions/competitions.actions';
import { selectCompetitions } from './store/competitions/competitions.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  @ViewChild('errorPageContainer', {read: ViewContainerRef}) $errorPageContainer: ViewContainerRef;
  public appReady: boolean;
  public loading: boolean;
  public showContent: boolean;
  public version: string = packageJson.version;
  private anchorsUtils: AnchorsUtils;

  constructor(
    private cloudPayService: CloudPayService, // to trigger and load the script, do not remove
    private store: Store<AppState>,
    private router: Router,
    private cd: ChangeDetectorRef,
    private loaderService: LoaderService,
    private wordpressService: WordpressService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private dynamicComponentLoaderService: DynamicComponentLoaderService,
  ) {

    this.appReady = false;
    this.loading = false;
    this.cloudPayService.loadCloudPayScript();
    CssVarsUtils.initScreenSizesOnRoot();
    this.anchorsUtils = new AnchorsUtils(router, renderer, document, -84);
  }

  ngOnInit(): void {
    this.document.body.parentElement.setAttribute('data-version', this.version);

    this.listenToLoading();
    this.storeData();

    combineLatest([
      this.wordpressService.setPages().pipe(
        catchError(err => {
          throw this.loadErrorPage(err);
        }),
      ),
      this.wordpressService.setCachedData(),
      this.store.select(selectApplicationReady),
      this.cloudPayService.cloudPayState(),
    ])
      .subscribe(([
                    pagesPath,
                    wpCachedDataIsReady,
                    dataStoreReady,
                    cloudPayState,
                  ]) => {

        if (pagesPath && wpCachedDataIsReady && dataStoreReady && !cloudPayState.isScriptLoading) {

          this.appReady = true;
          this.cd.markForCheck();

          setTimeout(() => {
            this.showContent = true;
            this.cd.markForCheck();
          }, 2000);
        }
      });
  }

  private loadErrorPage(error: Error): Error {

    if (!this.$errorPageContainer.get(0)) {
      this.dynamicComponentLoaderService.loadSingleComponent('error_page', this.$errorPageContainer, null, true);
      this.showContent = true;
    }

    return error;
  }

  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  private listenToLoading(): void {
    this.loaderService.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
        this.cd.markForCheck();
      });
  }

  private storeData(): void {

    this.store.dispatch(loadCompetitions());
    this.store.dispatch(loadWordpress());

    /*this.store.select(selectWordpressStatus).subscribe((status) => {
      if (status.error) {
        this.loadErrorPage(status.error);
      }
    });*/

    this.store.select(selectCompetitions).subscribe((competitions) => {

      if (competitions) {
        const competitionsList = competitions.filter((item) => {

          if (item.id && item.is_active && item.start_date) {

            const timeDifference = new Date(item.start_date).getTime() - new Date().getTime();
            const totalDays = Math.ceil(timeDifference / (1000 * 3600 * 24));

            return totalDays <= 30; // get competitions that start in 30 days time
          }

          return false;
        });

        this.store.dispatch(loadTeams({competitionsList: competitionsList}));
        this.store.dispatch(loadFixtures({competitionsList: competitionsList}));
      }
    });
  }
}
