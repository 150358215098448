import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { loadTeams, loadTeamsFailure, loadTeamsSuccess } from './teams.actions';
import { WordpressService } from '@streamamg/wordpress-lib';
import { WpTeams } from '../../templates/team-template/team.types';
import { configs } from '../../configs';
import { CurlingCanadaService } from '../../services/curling-canada.service';

@Injectable()
export class TeamsEffects {

  loadTeams$ = createEffect(() => this.actions$.pipe(
    ofType(loadTeams),
    switchMap((params) => combineLatest([
      this.curlingCanadaService.getTeams(params.competitionsList),
      this.wordpressService.getData<WpTeams>(configs.wordpress.paths.api + configs.wordpress.subPaths.teams),
    ])),
    map(([teamsApi, wpTeams]) => {

      if (teamsApi?.length) {

        Object.values(wpTeams).forEach((wpTeam) => {

          const teamsFiltered = teamsApi.filter((team) => team.main_team === wpTeam.confs.post_title);

          teamsFiltered.map((team) => {
            team.wp = wpTeam;
            return team;
          });
        });

        teamsApi.sort((a, b) => (a.main_team > b.main_team) ? 1: ((b.main_team > a.main_team) ? -1: 0));
      }

      let wpTeamsSorted = Object.values(wpTeams);
      if (wpTeams) {
        wpTeamsSorted = Object.values(wpTeams).sort((a, b) => (a.confs.post_title > b.confs.post_title) ? 1: ((b.confs.post_title > a.confs.post_title) ? -1: 0));
      }

      return loadTeamsSuccess({
        data: {
          teams: teamsApi,
          wpTeams: wpTeamsSorted,
        },
      });
    }),
    catchError(error => of(loadTeamsFailure({error}))),
  ), {dispatch: true});

  constructor(
    private actions$: Actions,
    private wordpressService: WordpressService,
    private curlingCanadaService: CurlingCanadaService,
  ) {
  }
}
