import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { loadCompetitions, loadCompetitionsFailure, loadCompetitionsSuccess } from './competitions.actions';
import { WordpressService } from '@streamamg/wordpress-lib';
import { configs } from '../../configs';
import { WpCompetitions } from '../../types/wordpress/competition.types';
import moment from 'moment';
import 'moment-timezone';
import { CompetitionStore } from '../../types/store.type';

@Injectable()
export class CompetitionsEffects {

  loadCompetitions$ = createEffect(() => this.actions$.pipe(
    ofType(loadCompetitions),
    switchMap((params) => combineLatest([
      this.wordpressService.getData<WpCompetitions>(configs.wordpress.paths.api + configs.wordpress.subPaths.competitions),
    ])),
    map(([wpCompetitions]) => {

      const competitions: CompetitionStore[] = [];

      Object.values(wpCompetitions).forEach((wpCompetition) => {

        const endDate = wpCompetition.dates?.end_date ? moment.utc(wpCompetition.dates?.end_date): null;
        const startDate = wpCompetition.dates?.start_date ? moment.utc(wpCompetition.dates?.start_date): null;

        competitions.push({
          id: wpCompetition.competition_id,
          wp: wpCompetition,
          is_active: endDate === null || (endDate >= moment() || !!localStorage.getItem('mockup_cc_api')),
          start_date: startDate ? startDate.toString(): null,
          end_date: endDate ? endDate.toString(): null,
        });
      });

      competitions.sort((a, b) => (a.wp.confs.post_title > b.wp.confs.post_title) ? 1: ((b.wp.confs.post_title > a.wp.confs.post_title) ? -1: 0));

      return loadCompetitionsSuccess({
        data: {
          competitions,
        },
      });
    }),
    catchError((error) => of(loadCompetitionsFailure({error}))),
  ), {dispatch: true});

  constructor(
    private actions$: Actions,
    private wordpressService: WordpressService,
  ) {
  }

}
