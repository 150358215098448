import { createReducer, on } from '@ngrx/store';
import {
  loadCompetitionsFailure,
  loadCompetitionsSuccess,
  selectCompetitionById,
  selectCompetitionBySlug,
} from './competitions.actions';
import { StoreState } from '../index';
import { CompetitionStore } from '../../types/store.type';

export const featureKey = 'competitions';

export interface State extends StoreState {
  competitionId?: string;
  slug?: string;
  competitions?: CompetitionStore[];
}

export const initialState: State = {};

export const reducer = createReducer(
  initialState,
  on(loadCompetitionsSuccess, (state, {data}) => ({
    ...state,
    competitions: data.competitions,
    status: {...initialState.status, ready: true},
  })),
  on(loadCompetitionsFailure, (state, {error}) => ({...state, status: {loading: false, error, ready: true}})),
  on(selectCompetitionById, (state, {competitionId}) => ({...state, competitionId})),
  on(selectCompetitionBySlug, (state, {slug}) => ({...state, slug})),
);

