import { createReducer, on } from '@ngrx/store';
import { loadWordpressFailure, loadWordpressSuccess } from './wordpress.actions';
import { WpCategory } from '../../types/wordpress/category.type';
import { StoreState } from '../index';
import { WpPage } from '@streamamg/wordpress-lib';

export const featureKey = 'wordpress';

export interface State extends StoreState {
  categories?: WpCategory[];
  genders?: WpPage[];
  teamCategories?: WpPage[];
  tournamentPhases?: WpPage[];
}

export const initialState: State = {};

export const reducer = createReducer(
  initialState,
  on(loadWordpressSuccess, (state, {data}) => ({
    ...state,
    categories: data.categories,
    genders: data.genders,
    teamCategories: data.teamCategories,
    tournamentPhases: data.tournamentPhases,
    status: {...initialState.status, ready: true},
  })),
  on(loadWordpressFailure, (state, {error}) => ({...state, status: {loading: false, error, ready: false}})),
);

