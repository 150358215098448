import { createSelector } from '@ngrx/store';
import { AppState } from '../index';
import { featureKey } from './competitions.reducer';

export const CompetitionsState = (state: AppState) => state[featureKey];

export const selectCompetitions = createSelector(
  CompetitionsState,
  (state) => state.competitions,
);

export const selectCompetitionsStatus = createSelector(
  CompetitionsState,
  (state) => state.status,
);

export const selectSelectedCompetitionById = createSelector(
  CompetitionsState,
  (state) => {

    return state.competitions?.find((competition) => competition.id === state.competitionId);
  },
);

export const selectSelectedCompetitionBySlug = createSelector(
  CompetitionsState,
  (state) => {

    return state.competitions?.find((competition) => competition.wp.confs.post_slug === state.slug);
  },
);
