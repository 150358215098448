import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginModalComponent } from './login-modal.component';
import { LoaderModule } from '@streamamg/components-lib';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [LoginModalComponent],
  exports: [
    LoginModalComponent,
  ],
  imports: [
    CommonModule,
    LoaderModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class LoginModalModule {
}
