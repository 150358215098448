<div *ngIf="wpGlobalSettings?.login"
     [class.opened]="isOpened"
     class="modal">

  <div class="modal-content">

    <amg-loader *ngIf="isLoading"
                class="pabsolute"></amg-loader>

    <div (click)="closeModal()"
         class="close-btn">
      <div class="close-btn__icon"></div>
    </div>

    <div class="box-data">
      <div class="header">
        <h3 class="header__title font-h1">{{wpGlobalSettings.login.labels.title}}</h3>
        <p class="header__description font-body-text font-meta">{{wpGlobalSettings.login.labels.description}}</p>
      </div>
      <div class="content">
        <form (submit)="formSubmit()"
              [formGroup]="loginForm"
              class="content__form">
          <fieldset class="content__form-fieldset">
            <label class="content__form-label font-meta-information"
                   for="email">{{wpGlobalSettings.login.labels.email?.label}}</label>
            <div class="content__form-fieldset-input-wrapper">
              <input [ngClass]="isFieldInvalid('emailaddress') && 'invalid-field'"
                     [placeholder]="wpGlobalSettings.login.labels.email?.input"
                     class="content__form-input font-input-big"
                     formControlName="emailaddress"
                     id="email"
                     type="email"/>
              <div class="exclamation"></div>
            </div>
            <div *ngIf="isFieldInvalid('emailaddress')"
                 class="error-txt font-input-small">
              <span *ngIf="loginForm.controls['emailaddress'].hasError('required')"
                    [textContent]="wpGlobalSettings.login.labels.email?.error_required"></span>
              <span *ngIf="loginForm.controls['emailaddress'].hasError('email')"
                    [textContent]="wpGlobalSettings.login.labels.email?.error"></span>
              <span *ngIf="loginForm.controls['emailaddress'].hasError('server')"
                    [textContent]="loginForm.controls['emailaddress'].getError('server')"></span>
            </div>
          </fieldset>
          <fieldset class="content__form-fieldset">
            <label class="content__form-label font-meta-information"
                   for="password">{{wpGlobalSettings.login.labels.password?.label}}</label>
            <div class="content__form-fieldset-input-wrapper">
              <input [ngClass]="isFieldInvalid('password') && 'invalid-field'"
                     [placeholder]="wpGlobalSettings.login.labels.password?.input"
                     [type]="showPassword ? 'text' : 'password'"
                     class="content__form-input font-input-big"

                     formControlName="password"
                     id="password"/>
              <div (click)="showPassword = !showPassword"
                   [ngClass]="showPassword ? 'show-password' : 'hide-password'"
                   class="content__form-fieldset-password-toggle exclamation">
              </div>
            </div>
            <div *ngIf="isFieldInvalid('password')"
                 class="error-txt font-input-small">
              <span *ngIf="loginForm.controls['password'].hasError('required')"
                    [textContent]="wpGlobalSettings.login.labels.password?.error_required"></span>
              <span *ngIf="loginForm.controls['password'].hasError('minlength')"
                    [textContent]="wpGlobalSettings.login.labels.password?.error"></span>
              <span *ngIf="loginForm.controls['password'].hasError('server')"
                    [textContent]="loginForm.controls['password'].getError('server')"></span>
            </div>

            <div class="txt-forgot-pwd font-meta font-small-text">
              <a [routerLink]="pagesPaths.forgot_password?.confs?.post_permalink"
                 [textContent]="wpGlobalSettings.login.labels.link_forgot_password"></a>
            </div>
          </fieldset>
          <div class="content__form-cta">
            <button class="btn btn-primary btn-brand"
                    type="submit">
              <span class="font-cta-primary-secondary">{{ wpGlobalSettings.login.labels.submit }}</span>
            </button>

            <div *ngIf="serverErrors.length"
                 class="error-txt">
              <span *ngFor="let error of serverErrors"
                    [textContent]="error"></span>
            </div>

          </div>
        </form>
      </div>
      <div class="bottom">
        <div class="txt-new-account">
          <h4 class="bottom__text font-meta-information">{{wpGlobalSettings.login.labels.no_account}}</h4>

          <a [routerLink]="pagesPaths.packages?.confs?.post_permalink"
             class="bottom__cta font-meta-information">
            <span>{{ wpGlobalSettings.login.labels.sign_in }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

