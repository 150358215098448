<header [class.menu-opened]="menuOpened"
        class="header">
  <div class="header__logo">
    <amg-link (click)="closeMenu()"
              path="/#"></amg-link>
  </div>

  <div class="header__menu-content">
    <nav>
      <div *ngFor="let item of menu; let i = index"
           [class.opened]="submenusOpened['i-' + i]"
           [class.submenu]="item.child_items?.length > 0"
           class="item {{item.css_class}} font-navigation">

        <amg-link *ngIf="item.url; else noLink"
                  [className]="item.css_class"
                  [path]="item.url"
                  [text]="item.title">
        </amg-link>

        <ng-template #noLink>
          <div class="btn-toggle">
            <div [className]="item.css_class"
                 [textContent]="item.title"></div>
            <div
              (click)="toggleSubmenu(i)"
              class="btn-toggle-submenu"></div>
          </div>

        </ng-template>

        <ng-container *ngIf="item.child_items?.length > 0">
          <ul
            class="submenu__container">

            <li *ngFor="let subItem of item.child_items">
              <amg-link [cssClass]="subItem.css_class"
                        [path]="subItem.url">
                <span [innerHTML]="subItem.title"
                      class="font-sub-items-navigation"></span>
              </amg-link>
            </li>
          </ul>
        </ng-container>
      </div>
    </nav>

    <div class="header__secondary-menu">
      <app-authentication-button [typeCta]="'subscribe'"
                                 buttonClasses="btn-primary"
                                 class="subscribe subscribe-mobile"></app-authentication-button>

    </div>
  </div>
  <div class="header__secondary-menu-clone">
    <app-authentication-button [typeCta]="'subscribe'"
                               buttonClasses="btn-primary"
                               class="subscribe subscribe-desktop"></app-authentication-button>

    <div (click)="searchToggle()"
         class="search-btn">
      <div class="search-btn__icon"></div>
    </div>
    <div class="separator"></div>

    <app-login-header [isLoggedIn]="userState.isLoggedIn"
    ></app-login-header>
  </div>

  <div (click)="toggleMenu()"
       class="header__menu-mobile-icon">
    <button [class.is-active]="menuOpened"
            aria-label="hamburger"
            class="hamburger"
            role="button">
      <span class="hamburger__line hamburger__line--top"></span>
      <span class="hamburger__line hamburger__line--middle"></span>
      <span class="hamburger__line hamburger__line--bottom"></span>
    </button>
  </div>
</header>

<div [class.search-container--opened]="searchOpened"
     class="search-container">
  <div (click)="searchToggle()"
       class="search-container__close-btn"></div>

  <div class="search-container__content">
    <label [textContent]="'search' | label"
           class="search-container__label font-display"></label>
    <div class="field-container">
      <form (ngSubmit)="submitSearch($event)"
            [formGroup]="formSearch"
            class="search-container__form">
        <div class="free-text-container">
          <button aria-label="search"
                  class="free-text-container__btn"
                  role="button"
                  type="submit"></button>
          <input #searchInput
                 [placeholder]="'search_placeholder' | label"
                 class="free-text-container__input font-meta-information"
                 formControlName="q"
                 type="text">
          <div (click)="searchClean()"
               *ngIf="formSearch.value?.q"
               [textContent]="wpFilters.labels['cancel']"
               class="free-text-container__clean font-labels"></div>
        </div>
      </form>
    </div>
  </div>
</div>
