import { createAction, props } from '@ngrx/store';
import { State } from './wordpress.reducer';

export const loadWordpress = createAction(
  '[Wordpress] Load Wordpress',
);

export const loadWordpressSuccess = createAction(
  '[Wordpress] Load Wordpress Success',
  props<{ data: State }>(),
);

export const loadWordpressFailure = createAction(
  '[Wordpress] Load Wordpress Failure',
  props<any>(),
);
