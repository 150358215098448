import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { loadWordpress, loadWordpressFailure, loadWordpressSuccess } from './wordpress.actions';
import { WordpressService, WpPage } from '@streamamg/wordpress-lib';
import { configs } from '../../configs';
import { WpCategories } from '../../types/wordpress/category.type';

@Injectable()
export class WordpressEffects {

  loadWordpress$ = createEffect(() => this.actions$.pipe(
    ofType(loadWordpress),
    switchMap(() => combineLatest([
      this.wordpressService.getData<WpCategories>(configs.wordpress.paths.api + configs.wordpress.subPaths.categories),
      this.wordpressService.getData<WpPage>(configs.wordpress.paths.api + configs.wordpress.subPaths.tournament_phases),
      this.wordpressService.getData<WpPage>(configs.wordpress.paths.api + configs.wordpress.subPaths.genders),
      this.wordpressService.getData<WpPage>(configs.wordpress.paths.api + configs.wordpress.subPaths.team_categories),
    ])),
    map(([categories, tournamentPhases, genders, teamCategories]) => {

      if (categories && tournamentPhases && genders && teamCategories) {

        return loadWordpressSuccess({
          data: {
            categories: Object.values(categories),
            tournamentPhases: Object.values(tournamentPhases),
            genders: Object.values(genders),
            teamCategories: Object.values(teamCategories),
          },
        });
      }

      return loadWordpressFailure([categories, tournamentPhases, genders, teamCategories]);
    }),
    catchError(error => of(loadWordpressFailure({error}))),
  ), {dispatch: true});

  constructor(
    private actions$: Actions,
    private router: Router,
    private wordpressService: WordpressService,
  ) {
  }
}
