import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseComponentDirective, MenuService, WpMenuDataItem } from '@streamamg/amg-common';
import { combineLatest } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { CarouselLibModule } from '@streamamg/carousel-lib';
import { ImageModule, LinkModule } from '@streamamg/components-lib';
import { RouterModule } from '@angular/router';
import { WordpressService } from '@streamamg/wordpress-lib';
import { WpFooter, WpGlobalSettings, WpSocial } from '../../types/wordpress/global-settings.type';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    CarouselLibModule,
    ImageModule,
    RouterModule,
    LinkModule,
  ],
})
export class FooterComponent extends BaseComponentDirective implements OnInit {
  public socialMenu: WpSocial[];
  public footerNavigation: WpMenuDataItem[];
  public footerBottomLinks: WpMenuDataItem[];
  public footer: WpFooter;

  constructor(
    private cd: ChangeDetectorRef,
    private menuService: MenuService,
    private wordpressService: WordpressService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.footer = this.wordpressService.getWpGlobalSettings<WpGlobalSettings>().footer;

    super.subSink = combineLatest([
      this.menuService.getMenuDataItems('social'),
      this.menuService.getMenuDataItems('footer'),
      this.menuService.getMenuDataItems('footer-bottom'),
    ]).subscribe(([
                    socialMenu,
                    footerNavigation,
                    bottomLinks,
                  ]) => {
      if (socialMenu) {
        this.socialMenu = socialMenu.items;
      }
      if (footerNavigation) {
        this.footerNavigation = footerNavigation.items;
      }
      if (bottomLinks) {
        this.footerBottomLinks = bottomLinks.items;
      }

      this.cd.markForCheck();
    });
  }
}
